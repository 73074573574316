<!--
    @name: FormDesigner
    @description：FormDesigner
    @author: ZengWei
    @date: 2021-12-09 09:49
-->
<template>
	<div class="form-designer" v-loading="loading">
		<iframe
			ref="designForm"
			v-if="pathSrc"
			:src="pathSrc"
			style="width: 100%; height: 100%"
			frameborder="0"
		></iframe>
		<el-dialog
			title="试卷设计预览"
			:visible.sync="dialogVisible"
			:fullscreen="true"
			custom-class="vertical-center"
		>
			<div>
				<QuesParser v-if="previewData && dialogVisible" ref="formParser" :form-data="previewData" />
				<skeleton v-else></skeleton>
			</div>
		</el-dialog>
	</div>
</template>

<script>
  import QuesParser from "@/custom-component/form/quesParser/QuesParser";
	import skeleton from "@/custom-component/form/newParser/skeleton.vue";
	import { Dialog } from 'element-ui'

	export default {
		name: "FormDesigner",
		components: {
      QuesParser,
      skeleton,
			'el-dialog': Dialog,
		},
		props: {
			path: {
				type: String,
				default: '',
			},
			formUuid: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				loading: true,
				iframeWindow: null,
				formDesignData: null,
				dialogVisible: false,
				previewData: null,
				pathSrc: '',
        paperId: this.$route.query.paperId
			}
		},
		created() {
			if(process.env.NODE_ENV === 'production'){
				this.pathSrc = "/base/designForm/#/bankEditor";
			} else {
				this.pathSrc = "/localForm/#/bankEditor";
			}
      if(this.paperId){
        this.pathSrc += '?paperId='+ this.paperId
      }
			window.previewPaper = this.previewPaper.bind(this);
		},
		mounted() {
			this.initIframeWindow();
		},
		methods: {
			initIframeWindow() {
				const _this = this;
        const timer = setInterval(() => {
          let iframe = this.$refs?.designForm?.contentWindow;
          if (typeof iframe?.formDesignJson === "function") {
            clearInterval(timer);
            // let param = { formUuid: this.formUuid }
            _this.loading = false;
          }
        }, 100);
			},
      previewPaper(formDesignData){
				this.previewData = formDesignData
				this.dialogVisible = true
			},
		},
	}
</script>

<style lang="less" scoped>
	.form-designer {
		height: 100%;
		background: #fff;
		text-align: left;
	}
</style>
